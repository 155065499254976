var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"170dc19bb6b3ce832bafe8bc375d0f384a4f5f59"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';

import { NODE_ENV_PROD, NODE_ENV_STG } from './src/common/constants';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
// const { SENTRY_HTTP_CODE_START } = parseInt(process.env.SENTRY_HTTP_CODE_START, 10);
// const { SENTRY_HTTP_CODE_END } = parseInt(process.env.SENTRY_HTTP_CODE_END, 10);
const shouldReportToSentry = [NODE_ENV_STG, NODE_ENV_PROD].includes(process.env.NEXT_PUBLIC_NODE_ENV);

if (shouldReportToSentry) {
  Sentry.init({
    release: process.env.npm_package_version,
    dsn: SENTRY_DSN,
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === 'xhr') {
        const data = {
          requestBody: hint.xhr.__sentry_xhr__.body,
          response: hint.xhr.response,
          responseUrl: hint.xhr.responseURL,
        };
        return { ...breadcrumb, data };
      }
      return breadcrumb;
    },
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: process.env.NEXT_PUBLIC_NODE_ENV,

    normalizeDepth: 10,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      "Can't find variable: _AutofillCallbackHandler",
    ],

    integrations: [
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[400, 599]],
        sendDefaultPii: true,

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        // failedRequestTargets: [
        //  "http://example.com/api/test",
        //  /(staging\.)?mypage\.com/,
        // ],
      }),
    ],
    denyUrls: [
      // Google Adsense
      /pagead\/js/i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /https?:\/\/.*\.clarity\.ms/,
      /https?:\/\/.*\.clarity\.ms\/.*/,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
