import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { setCookie } from 'nookies';
import ErrorsFactory from 'common/lib/errorHandler/errors';
import { useCountryDetails, useLayout } from 'service/hooks';
import { Store } from 'service/types';
import { RenderingRegistry } from 'common/lib/registries';
import { DataLayerConfigs } from 'common/lib/tracking';
import { pushPageViewEventToDataLayer } from 'common/lib/tracking/utils';
import ServiceConfigs from 'service/config';
import {
  useHandleCartDataBackup,
  usePersistedCountryDetails,
  usePersistedOperatorId,
  usePersistedReferenceId,
  usePersistedUserAddressId,
  useSettingPaymentLinkPersistedStates,
} from '../../hooks';
import { ErrorsConfig } from '../../lib/errorHandler';
import { getUtmData, setRedirectToOrderFastCustomAction, setUtmCookie, removeUtmCookie, isUtmExpired } from '../../utils';
import { LANGUAGE_EN } from '../../constants';

declare global {
  interface Window {
    fbq?: any;
  }
}

type AppInitializationProps = {
  storeMainInfo: Store;
  status: number;
  isManualOrder?: boolean;
  referenceId?: string;
  operatorId?: string;
};

const AppInitialization: React.FC<AppInitializationProps> = ({
  storeMainInfo,
  status,
  isManualOrder,
  referenceId,
  operatorId,
}) => {
  const [persistedCountryDetails, setPersistedCountryDetails] = usePersistedCountryDetails();
  const [, setPersistedOperatorId] = usePersistedOperatorId();
  const handleCartDataBackup = useHandleCartDataBackup();
  const [, , removePersistedUserAddressId] = usePersistedUserAddressId();
  const [, setReferenceId] = usePersistedReferenceId();
  const subdomain = ServiceConfigs.getSubDomain();
  const router = useRouter();
  const { t } = useTranslation('errors');
  const { data: storeLayoutDetails } = useLayout({ initialData: storeMainInfo });
  const { defaultCountry, storeUi, hasActiveVouchers } = storeLayoutDetails || {
    useDeliveryzonesMs: false,
    storeUi: storeMainInfo?.storeUi || {},
    hasActiveVouchers: storeMainInfo?.hasActiveVouchers || false,
  };

  const { data: countryDetails } = useCountryDetails({
    id: status === 404 ? '' : persistedCountryDetails?.id || defaultCountry?.id,
  });

  useSettingPaymentLinkPersistedStates(referenceId);

  useEffect(() => {
    if (operatorId) {
      setPersistedOperatorId(operatorId);
    }
  }, [operatorId]);

  useEffect(() => {
    if (referenceId) setReferenceId(referenceId);
  }, [referenceId]);

  useEffect(() => {
    handleCartDataBackup();
  }, []);

  useEffect(() => {
    const { utm_source: utmSource, utm_medium: utmMedium, utm_campaign: utmCampaign, fbclid } = router?.query;
    const utmData = getUtmData(subdomain);

    const updatedUtms = {
      utmSource: utmSource || utmData?.utmSource,
      utmMedium: utmMedium || utmData?.utmMedium,
      utmCampaign: utmCampaign || utmData?.utmCampaign,
    };

    const isNewUtm =
      utmSource !== utmData?.utmSource || utmMedium !== utmData?.utmMedium || utmCampaign !== utmData?.utmCampaign;

    if (isNewUtm) {
      setUtmCookie(updatedUtms, subdomain);
    } else if (utmData && isUtmExpired(utmData?.creationDate)) {
      removeUtmCookie(subdomain);
    }

    const maxAge = 30 * 24 * 60 * 60;
    const cookieOptions = { maxAge, path: '/' };

    if (fbclid) {
      const fbclidString = Array.isArray(fbclid) ? fbclid[0] : fbclid;
      setCookie(null, `user_fbclid_${subdomain}`, fbclidString, cookieOptions);
    }
  }, [router?.query]);

  useEffect(() => {
    if (isManualOrder) {
      RenderingRegistry.setIsManualOrdering();
    }
    ErrorsConfig.init({ t });
    setRedirectToOrderFastCustomAction({
      router,
      countryCode: persistedCountryDetails?.code,
    });
    if (!ServiceConfigs?.getUserToken()) removePersistedUserAddressId();
  }, []);

  // Initialize luxon
  useEffect(() => {
    const initializeLuxonSettings = async () => {
      const { Settings } = await import('luxon');
      Settings.defaultLocale = LANGUAGE_EN;
      if (countryDetails?.timeZone) Settings.defaultZone = countryDetails.timeZone;
    };

    initializeLuxonSettings();
  }, [countryDetails?.timeZone]);

  useEffect(() => {
    DataLayerConfigs.init();
    pushPageViewEventToDataLayer(router.asPath);
    ErrorsFactory.constructErrorsFactory(router.route);
  }, [router.route]);

  const { categoryLayout, productLayout, fontShape, buttonRounded, logoRounded } = storeUi || {};
  const defaultLayout = { categoryLayout, productLayout, fontShape, buttonRounded, logoRounded };
  RenderingRegistry.init(defaultLayout);
  RenderingRegistry.setHasActiveVouchers(hasActiveVouchers);

  useEffect(() => {
    const removeCartOnEvent = e => {
      const origin = e?.originalEvent?.origin || e?.origin;
      const originPattern = /(?:(dash(-([a-z0-9])+)?\.zyda\.com))/g;
      if (!originPattern.test(origin)) return;
      // Clear the local storage on closing the slideover of manual order
      window.localStorage.clear();
      window.sessionStorage.clear();
    };
    window?.addEventListener('message', removeCartOnEvent);
    return () => {
      window?.removeEventListener('message', removeCartOnEvent);
    };
  }, []);

  useEffect(() => {
    if (!persistedCountryDetails && countryDetails)
      setPersistedCountryDetails({ ...countryDetails, currency: defaultCountry?.currency });

    if (persistedCountryDetails && persistedCountryDetails.currency?.title_en !== defaultCountry?.currency?.title_en)
      setPersistedCountryDetails({ ...persistedCountryDetails, currency: defaultCountry?.currency });
  }, [countryDetails, persistedCountryDetails]);

  return <></>;
};

export default AppInitialization;
