import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { UTM_COOKIE_NAME } from '../constants';

export const setUtmCookie = (params, subdomain) => {
  const creationDate = new Date().toISOString();
  const utmData = {
    ...params,
    creationDate,
    subdomain,
  };
  const maxAge = 7 * 24 * 60 * 60;

  setCookie(null, `${UTM_COOKIE_NAME}_${subdomain}`, JSON.stringify(utmData), {
    path: '/',
    maxAge,
  });
};

export const getUtmData = subdomain => {
  const cookies = parseCookies();
  return cookies[`${UTM_COOKIE_NAME}_${subdomain}`] ? JSON.parse(cookies[`${UTM_COOKIE_NAME}_${subdomain}`]) : null;
};

export const isUtmExpired = creationDate => {
  const now = new Date();
  const expiryDate = new Date(new Date(creationDate).getTime() + 7 * 24 * 60 * 60 * 1000);
  return now > expiryDate;
};

export const removeUtmCookie = subdomain => {
  destroyCookie(null, `${UTM_COOKIE_NAME}_${subdomain}`, {
    path: '/',
  });
};
