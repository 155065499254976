import { gql } from 'graphql-request';

const storeLayoutQuery = gql`
  query ($subdomain: String!) {
    store(subdomain: $subdomain) {
      id
      titleAr
      titleEn
      isCommissionBased
      showPoweredBy
      photoUrl
      pageTitleEn
      pageTitleAr
      hideStoreName
      faviconUrl
      descriptionEn
      descriptionAr
      hasActiveVouchers
      defaultCountry {
        id
        currency
      }
      logoUrl
      preferredLanguage
      published
      defaultProductPrepTime
      pwaSettings {
        icon
        manifest
      }
      branchesCount
      storeUi {
        productLayout
        categoryLayout
        fontShape
        buttonRounded
        logoRounded
        theme {
          palette {
            primary
            headerBackgroundColor
          }
        }
      }
      enabledOrderModes {
        delivery
        pickup
        carPickup
        beach
      }
      scheduleInfo {
        interval
      }
      setting {
        scheduleOrdersEnabled
        deliveryVatInclusive
        useDeliveryzonesMs
        businessLegalDatum {
          companyNameAr
          companyNameEn
          vatIdNumber
        }
      }
      activeStorefrontCashbackProgram {
        percentage
      }
    }
  }
`;

export default storeLayoutQuery;
