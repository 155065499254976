import React, { useState, useEffect } from 'react';
import { ModalProvider, ThemeProvider } from '@zydalabs/storefront-components';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { parseCookies, setCookie } from 'nookies';
import { useTheme as useZacTheme, ThemeProvider as ZacThemeProvider } from '@zydalabs/zac-react';
import {
  updateRedirectToOrderFastCustomActionRoute,
  replaceRoute,
  customHistory,
  getLanguageDirection,
  getFullDomain,
} from 'common/utils';
import { useHandleCheckoutRedirection } from 'modules/checkoutModule/hooks';
import { DATA_LAYER_EVENTS, URLS, USER_AUTHENTICATION_COOKIE } from 'common/constants';
import { GlobalStyle } from 'common/styles';
import theme from 'common/utils/theme';
import { useLayout } from 'service/hooks';
import { Store } from 'service/types';
import { RenderingRegistry } from 'common/lib/registries';
import useTranslation from 'next-translate/useTranslation';
import { useGlobalSnackBar, usePersistedCurrentEvent, usePersistedOrderMode } from '../../hooks';
import { ErrorsConfig } from '../../lib/errorHandler';
import { useAuthorizeCartIfExists } from '../../../modules/productsModule/hooks';

const LayoutComponent = dynamic(() => import('common/components/Layout'));
const ErrorBoundaryComponent = dynamic(() => import('common/components/error'));
const StoreNotPublishedComponent = dynamic(() => import('../StoreNotPublished'));
const AppCrashComponent = dynamic(() => import('common/components/error/500'));
const StoreNotFoundComponent = dynamic(() => import('common/components/StoreNotFound'));
const LoadingScreenComponent = dynamic(() => import('common/components/LoadingScreen'));
const OrderFastLandingComponent = dynamic(() => import('common/components/OrderFastLanding'));

const EstimatedTimeComponent = dynamic(() => import('common/components/EstimatedTime'), { ssr: false });
const SnackBarComponent = dynamic(() => import('@zydalabs/storefront-components').then(components => components.SnackBar), {
  ssr: false,
});
const ModalComponent = dynamic(() => import('@zydalabs/storefront-components').then(components => components.Modal), {
  ssr: false,
});
type ScreenProps = {
  status: number;
  storeMainInfo: Store;
  lang: string;
  Component: any;
  pageProps: Record<any, any>;
  userToken: string;
  isOrderFastLandingPage: boolean;
  justAuthorized: boolean;
};

const Screen: React.FC<ScreenProps> = ({
  status,
  storeMainInfo,
  Component,
  pageProps,
  userToken,
  isOrderFastLandingPage,
  justAuthorized,
}) => {
  const router = useRouter();
  const { lang } = useTranslation();
  const [orderMode] = usePersistedOrderMode();
  const [currentEvent, setPersistedCurrentEvent] = usePersistedCurrentEvent();
  const [modalProps, setModalProps] = useState({ isOpen: false });
  const [showOrderfastLoading, setShowOrderfastLoading] = useState(justAuthorized);
  const userTokenCookie = parseCookies(null)[USER_AUTHENTICATION_COOKIE];

  const [snackBarDetails, setSnackBarDetails] = useGlobalSnackBar();

  const { data: storeLayoutDetails, isLoading: isLayoutLoading } = useLayout({ initialData: storeMainInfo });
  const { isLoading: isRoutingLoading, route: redirectionRoute } = useHandleCheckoutRedirection();
  const authorizeCartIfExists = useAuthorizeCartIfExists();

  const palette = storeMainInfo?.storeUi?.theme?.palette || storeLayoutDetails?.storeUi?.theme?.palette;
  const isManualOrder = RenderingRegistry.getIsManualOrder();
  const { fontShape, buttonRounded } = RenderingRegistry.getShowLayout();

  const languageDirection = getLanguageDirection(lang);
  const storefrontTheme = theme(palette, languageDirection);
  const zacTheme = useZacTheme();
  const finalTheme = {
    ...storefrontTheme,
    ...zacTheme,
  };

  useEffect(() => {
    // TODO: this should check on the routing also, because if we h
    //  ide the loading screen while the routing still loading, the redirection to address map / car will not be correctl
    if (justAuthorized) {
      const hideLoadingScreen = async () => {
        if (!isRoutingLoading && redirectionRoute) {
          if (router.pathname === URLS.CHECKOUT && redirectionRoute !== URLS.CHECKOUT) await replaceRoute(redirectionRoute);
          setShowOrderfastLoading(false);
        }
      };

      const authorizeCart = async () => {
        await authorizeCartIfExists();
        // remove token from url
        const { token, subdomain, referenceId, ...QueryWithoutTokenOrSubdomain } = router.query;
        await replaceRoute(
          { pathname: router.pathname, query: QueryWithoutTokenOrSubdomain },
          { locale: router.locale, shallow: true },
        );
        await hideLoadingScreen();
      };
      if (!userTokenCookie) setPersistedCurrentEvent({ ...currentEvent, [DATA_LAYER_EVENTS.LOGIN]: userToken });
      setCookie(null, USER_AUTHENTICATION_COOKIE, userToken, {
        maxAge: Date.now() + 5 * 365 * 24 * 60 * 60, // 5 Years From Now
        path: '/',
        ...(isManualOrder && {
          secure: true,
          sameSite: 'none',
        }),
      });
      authorizeCart();
    }
  }, [userToken, justAuthorized, isRoutingLoading, redirectionRoute, showOrderfastLoading]);

  useEffect(() => {
    customHistory.fetchCustomHistoryStack();

    const existingCookie = parseCookies(null)['cookie-test-domain'];
    if (!existingCookie) {
      const currentTime = new Date().toISOString().split('T')[1]; // Extract only the time portion
      const uniqueValue = `test value - ${currentTime}`;
      // Set the cookie only if it doesn't exist
      setCookie(null, 'cookie-test-domain', uniqueValue, {
        maxAge: 2 * 60 * 60, // 2 hours in seconds
        path: '/',
        domain: getFullDomain(),
      });
    }
  }, []);

  useEffect(() => {
    if (customHistory.getHistoryStackLength() === 0) {
      customHistory.push(router.route);
    }
    updateRedirectToOrderFastCustomActionRoute(router);
    if (snackBarDetails?.show)
      setSnackBarDetails({ show: false, setShowSnackBar: () => snackBarDetails.setShowSnackBar(false) });
    if (modalProps.isOpen) setModalProps({ isOpen: false });
  }, [router.asPath]);

  const CurrentScreeComponent = () => {
    if (isOrderFastLandingPage) return <OrderFastLandingComponent />;
    if (status === 404) return <StoreNotFoundComponent />;
    if (status >= 500) return <AppCrashComponent />;
    if (!storeLayoutDetails?.published) return <StoreNotPublishedComponent />;
    ErrorsConfig.setErrorHandlers({ onModalError: setModalProps, onSnackBarError: setSnackBarDetails });
    return (
      <ErrorBoundaryComponent>
        {showOrderfastLoading ? (
          <LoadingScreenComponent />
        ) : (
          <LayoutComponent
            language={lang}
            isLoading={isLayoutLoading}
            storeLayoutDetails={storeLayoutDetails}
            primaryColor={storefrontTheme?.color.primary}
          >
            <ModalProvider value={{ modalProps, setModalProps }}>
              <ModalComponent />
              <SnackBarComponent {...snackBarDetails} />
              {!!orderMode && <EstimatedTimeComponent key="estimatedTimeComponent" />}
              <Component {...pageProps} /> {/* eslint-disable-line react/jsx-props-no-spreading */}
            </ModalProvider>
          </LayoutComponent>
        )}
      </ErrorBoundaryComponent>
    );
  };

  if (router?.pathname.includes('/rider/') || router?.pathname.includes('/links')) {
    return (
      <ZacThemeProvider
        themeMode="light"
        primaryColor={storefrontTheme?.color.primary}
        fontTheme={fontShape}
        roundedButtons={buttonRounded}
      >
        <ThemeProvider theme={finalTheme}>
          <GlobalStyle />
          <Component {...pageProps} />
        </ThemeProvider>
      </ZacThemeProvider>
    );
  }

  return (
    <ZacThemeProvider
      themeMode="light"
      primaryColor={storefrontTheme?.color.primary}
      fontTheme={fontShape}
      roundedButtons={buttonRounded}
    >
      <ThemeProvider theme={finalTheme}>
        <GlobalStyle />
        {CurrentScreeComponent()}
      </ThemeProvider>
    </ZacThemeProvider>
  );
};

export default Screen;
